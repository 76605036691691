<template>
    <TsNuxtCard :key="product.code" append-class="p-1 m-1 w-48 bg-idle-white flex-none" no-border>
      <TsRow append-class="justify-center pt-4">
        <NuxtImg
          width="105"
          height="105"
          class="rounded-xl bg-white"
          :src="product?.image"
        />
      </TsRow>
      <TsTypography as="p" size="xs" append-class="text-right mr-2">{{ product?.code }}</TsTypography>
  
      <TsColumn>
        <TsTypography append-class="mb-0 text-idle-black">
          <span class="font-semibold">{{ product?.brand }}</span> {{ product?.full_name }}
        </TsTypography>
        <TsRow append-class="m-0 justify-between">
          <TsTypography
            v-if="(product?.prices && product?.prices?.raw && product?.prices?.was_price_formatted)
                && product?.prices?.raw.gross < product?.prices.was_price_raw"
            size="sm"
            append-class="mb-0 line-through leading-tight mt-1"
          >
            {{ product?.prices?.was_price_formatted }}
          </TsTypography>
  
          <TsRatings v-if="productRatings >= 0 " :modelValue="productRatings" :label="`(${productReviews})`" :read-only="true" :stars="5"/>
        </TsRow>
        <TsRow append-class="m-0">
          <TsTypography variant="h2" weight="bold" append-class="text-idle-black leading-normal mb-0 leading-tight">
            {{ rootStore.isIncVat ? product?.prices?.formatted.gross : product?.prices?.formatted.net }}
          </TsTypography>
          <TsTypography v-if="product.prices && product.prices.formatted.per_unit" append-class="mt-1 ml-2" size="xs">
            {{ product?.prices?.formatted.per_unit }}
          </TsTypography>
        </TsRow>
        <TsTypography size="xs" append-class="leading-tight">{{ rootStore.isIncVat ? 'Excl.' : 'Inc.' }} VAT
            {{ rootStore.isIncVat ? product?.prices?.formatted.net : product?.prices?.formatted.gross }}
        </TsTypography>
        <TsButton
          icon="bx:cart"
          size="sm"
          :label="addToTrolleyLabel"
          append-class="mt-3"
          outlined
          block
        />
      </TsColumn>
    </TsNuxtCard>
  </template>
  
  <script lang="ts" setup>
  import { defineProps } from 'vue';

  const productAttributeStore = useProductAttributeStore();
  const rootStore = useRootStore();
  
  const props = defineProps<{
    product: {
      code: string;
      image: string;
      brand: string;
      full_name: string;
      prices: {
        was_price_formatted?: string;
        was_price_raw?:string;
        formatted: {
          net: string;
          gross: string;
          per_unit?: string;
        };
      };
      other_attributes: Array<{
        id: number;
        key: string;
        value: string;
      }>;
    };
    addToTrolleyLabel?: string;
  }>();
  

  const productReviews = ref(
  productAttributeStore.getNumberOfReviews(props.product?.other_attributes!)||0
);

function convertToNearestWholeNumber(num: number) {
  const firstDecimalPlace = Math.floor(num * 10) % 10;
  if (firstDecimalPlace < 5) {
    return Math.floor(num);
  } else {
    return Math.ceil(num);
  }
}
  
const productRatings = ref(
  convertToNearestWholeNumber(
    Number(
      productAttributeStore.getProductRating(props.product?.other_attributes!)
    )
  )
);
  </script>
  
  <style scoped>
  </style>
  